.tuva-round-progress {
  &.circular-progress {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner-circle {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .percentage {
      position: relative;
      font-size: 24px;
    }
  }
}
