.tuva-modal-close {
  position: fixed;
  right: 30px;
  top: 20px;
  outline: none;
  width: 30px;
  height: 30px;
  padding: 6px;
  border: none;
  background: none;
  &::before {
    color: #fff !important;
    line-height: 0.55 !important;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 36px !important;
    background: transparent;
    content: '\00D7';
    font-weight: 400;
    text-align: center;
  }
}
.popup.about-model {
  .markdown-wrapper {
    max-width: 100% !important;
  }
}
