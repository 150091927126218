.trigger {
  position: absolute;
  padding: 10px 0px;
  top: 46%;
  left: -5px;
  cursor: pointer;
  z-index: 1000;
  .ti {
    font-size: 8px;
  }
}
