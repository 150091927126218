.question-blocks-wrapper {
  // padding-top: 20px;
  p {
    font-size: 15px !important;
  }

  .question-block-btn {
    height: 40px;
    width: 30px;
    text-align: right;
    &:hover {
      .ti {
        opacity: 0.6;
      }
    }
  }

  input[type='text'] {
    height: 40px !important;
  }
  .question-block-header {
    p {
      font-size: 16px !important;
    }
  }
  .radio-question-block,
  .checkbox-question-block {
    .custom-radio {
      /* remove standard background appearance */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* create custom radiobutton appearance */
      display: inline-block;
      width: 20px;
      height: 17px;
      padding: 2px;
      margin: 0 8px 0 3px;
      cursor: pointer;
      /* background-color only for content */
      background-clip: content-box;
      border: 1px solid #004445;
      background-color: transparent;
      border-radius: 10px;
      &:checked {
        background-color: #004445;
      }
    }
  }

  .checkbox-question-block {
    .custom-checkbox {
      border: 1px solid #004445;
      border-radius: 2px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 20px;
      height: 16px;
      cursor: pointer;
    }

    .custom-checkbox:checked {
      background: #004445
        url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==')
        2px 2px no-repeat;
    }
  }
}
.solution-markdown-editor {
  button[title='Alert'],
  button[title='Tuva Attributes'],
  button[title='Youtube'],
  button[title='Vimeo'],
  button[title='Link'],
  button[title='Image'],
  button[title='Tuva Icons'] {
    display: none !important;
  }
  button[title='Break to Next Line'] {
    border-right: none !important;
  }
  .markdownEditor .cm-editor {
    min-height: 50px !important;
  }
}
