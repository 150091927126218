.overview-info {
  &.table {
    display: block;
    overflow-x: auto;
    .th-question-type {
      min-width: 100px;
    }
    .th-question {
      min-width: 300px;
    }
  }
}
