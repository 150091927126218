.content-label {
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  &.label-draft {
    background-color: #ffebed;
  }
  &.label-private {
    background-color: #e6e9f7;
  }
  &.label-solution {
    background-color: #f9f6bf;
    border: 1px solid #3d4146;
    font-weight: 600 !important;
    line-height: 1.4;
  }
  &.label-eld-support {
    background-color: #fbe5f0;
    color: #8c1a64;
    border: 1px solid #f268aa;
    font-weight: 600 !important;
  }
}
