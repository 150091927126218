// content_block_v2_skeleton view

.content_block_v2_skeleton {
  --bg: #dddbdd;
  --glare-bg: linear-gradient(
    90deg,
    transparent,
    hsla(0, 0%, 100%, 0.4),
    transparent
  );
  position: relative;
  background-color: var(--bg);
  overflow: hidden;
}

.content_block_v2_skeleton::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--glare-bg);
  transform: translateX(-100%);
  animation: content_block_v2_skeleton-glare 1.75s infinite ease-out;
  z-index: 1;
}

@keyframes content_block_v2_skeleton-glare {
  to {
    transform: translateX(100%);
  }
}

.content_block_v2_card {
  --content_block_v2_card-radius: 6px;
  min-width: 280px;
  height: 410px;
  margin: 20px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
}

.content_block_v2_card__img {
  width: 100%;
  height: 200px;
}

.content_block_v2_card__body {
  padding: 16px 16px 48px;
}

.content_block_v2_card__text {
  font-size: 1rem;
  height: 1em;
  border-radius: 20px;
}

.content_block_v2_card__body > .content_block_v2_card__text {
  margin: 0.5em 0;
}

.content_block_v2_card__body > .content_block_v2_card__text:first-child {
  margin-top: 0.25rem;
}

.content_block_v2_card__body > .content_block_v2_card__text:last-child {
  margin-bottom: 0.25rem;
  width: 66%;
}

// content_block_v2_skeleton view

.content-v2-info-box:hover {
  &.bg-green {
    background-color: #005f64 !important;
  }
}

// Content-block override
.content-block h3 {
  height: 60px !important;
  -webkit-line-clamp: 2 !important;
}

.content-block .description {
  -webkit-line-clamp: 3 !important;
  height: 72px !important;
}

.content-block .tuva-source {
  -webkit-line-clamp: 1 !important;
  height: 20px !important;
}

.content-v2-info-box p {
  display: -webkit-box;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical;
  height: 68px !important;
  overflow: hidden;
}

.content-block_image-v2 {
  height: 120px;
  &.img-min-width {
    min-width: 333px;
  }
}

.content-block_info_v2 {
  .tags_wrapper {
    height: 25px;
  }
  .content_subtype {
    margin-right: 5px;
  }
}

.content-block_extrainfo:not(.tags) {
  flex-direction: row !important;
}
