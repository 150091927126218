$dataset-activity-collapsed-width: 40px;

.large-text {
  zoom: 120%;
}

.extra-large-text {
  zoom: 140%;
}

.seventy.expand {
  width: calc(100% - #{$dataset-activity-collapsed-width});
}

.seventy,
.thirty {
  transition: all 0.382s;
}

.collapsible-area {
  position: relative;

  &.collapsed {
    width: $dataset-activity-collapsed-width;

    .main-content {
      display: none;
    }

    .rotated {
      display: block;
    }

    .trigger {
      left: -7px;
    }
  }

  .rotated {
    height: 100%;
    display: none;
    overflow: hidden;
    cursor: pointer;

    .vertical-text {
      font-size: 15px;
      line-height: 35px;
      height: $dataset-activity-collapsed-width;
      white-space: nowrap;
      transform: rotate(90deg);
      transform-origin: left top 0;
      font-weight: bold;
      margin-left: $dataset-activity-collapsed-width;
      float: left;
      padding-left: 20px;
    }
  }
}

.seventy,
.thirty {
  height: inherit;
}
