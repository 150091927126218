/* Ensure the container allows wrapping on small screens */
.activity-buttons {
  .btn {
    margin-top: 8px;
  }
}

/* Media query for screen sizes < 662px */
@media (max-width: 662px) {
  .activity-buttons .ml-2 {
    margin-left: 0 !important; /* Remove left margin for small screens */
  }
}
