.collapsible-wrapper {
  .collapsible-sidebar {
    z-index: 10;
    .close-btn {
      right: 8px;
      z-index: 11;
      top: 8px;
    }
  }
  .drawer-overlay {
    background: rgba(0, 0, 0, 0.2);
    z-index: 9;
  }
  .toggle-menu {
    z-index: 2;
  }
}
