$correct-answer-color: #004445 !important;
$wrong-answer-color: #a71931 !important;

.markdown-wrapper {
  max-width: 75ch;
  line-height: 1.5;
  font-size: 16px;
  input.form-control[type='radio'],
  input.form-control[type='checkbox'] {
    box-shadow: none;
  }
  label {
    font-weight: 400 !important;
  }
  figure img {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: -ms-zoom-in;
  }
  iframe {
    max-width: 100%;
  }
  fieldset {
    min-inline-size: auto;
    &:disabled iframe {
      pointer-events: none;
      filter: grayscale(100%);
    }
  }
  ul,
  ol {
    margin: 0.75em 0 0.75em 35px !important;
  }

  li {
    line-height: 1.5;
  }

  table {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    margin-bottom: 1em;

    td,
    th {
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 6px 13px;
    }

    th {
      font-weight: bold;
    }
  }

  blockquote {
    margin: 1.5em 0;
    padding: 0 0 0 15px;
    border-left: 5px solid #eee;
    &:before {
      display: none;
    }
  }

  a {
    word-wrap: break-word;
  }

  p {
    margin: 0 0 1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 0.5em;
    font-weight: normal;
  }
  h1 {
    font-size: 36px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 18px !important;
  }
  .options-wrapper.invalid {
    border: 1px solid red;
    border-radius: 5px;
  }
  .question-wrapper {
    margin-bottom: 12px;
    .question-label {
      .solution-wrapper:hover {
        background-color: #f2e969 !important;
      }
    }
  }
  .question-wrapper.correct-answer {
    border-color: $correct-answer-color;
    .text-green {
      color: $correct-answer-color;
    }
  }
  .question-wrapper.wrong-answer {
    border-color: $wrong-answer-color;
    .text-red {
      color: $wrong-answer-color;
    }
  }

  // TUVA ALERT
  .tuva-alert {
    padding: 5px 15px;
    border: 2px solid;
    border-radius: 5px;
    margin: 10px 0 30px;

    .tuva-alert-content {
      padding-top: 10px;
    }

    .tuva-alert-icon {
      padding-bottom: 0 !important;
      text-align: left;

      i[class^='ti-alert-'] {
        font-size: 20px;
        line-height: 0;
      }
    }
  }

  img {
    max-width: 100%;
  }

  .question {
    textarea,
    input[type='input'] {
      padding: 0.75em;
      margin: 0.75em 0;
    }
    .question-options {
      li {
        position: relative;
        padding-left: 20px;
        padding-bottom: 6px;

        label {
          input + span {
            margin-left: -17px;
          }
          input {
            position: relative;
            left: -20px;
            top: -1px;
          }
          img {
            display: block;
          }
        }
      }
    }
  }
  .graph-question {
    margin-top: 0.75em;
  }
}

div.seventy:first-child .markdown-wrapper {
  max-width: none !important;
  > *:not(ul, ol, h1, h2, h3) {
    display: block;
    max-width: 600px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  > div.zoomable-image,
  > iframe {
    max-width: 100% !important;
  }
  .tuva-alert {
    display: table;
    width: 100%;
    box-sizing: border-box;
    border-color: #f7f6f1;

    .tuva-alert-icon {
      padding-bottom: 0;
      vertical-align: middle;
      width: 100px;
      padding-right: 10px;
      display: table-cell;
    }

    .tuva-alert-content {
      vertical-align: middle;
      display: table-cell;
    }
  }
}

// Keyword Popup

.keyword-popover-text {
  position: relative;
  z-index: 1;
  &::after {
    border-bottom: 2px dotted #000;
    bottom: -3px;
    content: '';
    height: 0.75rem;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    margin-right: 3px;
    margin-left: 3px;
  }
}

.keyword-popover-overlay {
  opacity: 0;
  position: absolute;
  transition: opacity 300ms;
  z-index: 9999;
  box-shadow: 0 30px 90px -20px rgba(0, 0, 0, 0.3),
    0 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 3px;

  .popup-arrow-bottom:after {
    content: ' ';
    position: absolute;
    right: 10px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #ece01b;
  }

  .popup-arrow-top:after {
    content: ' ';
    position: absolute;
    right: 10px;
    bottom: -15px;
    border-top: 15px solid #ece01b;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }

  .keyword-popup {
    padding: 20px 20px 25px;
    background: #ece01b;
    border-radius: 5px;
    width: 320px;
    position: relative;
    transition: all 2s ease-in-out;

    .keyword-content {
      overflow: auto;
      .o-vertical-spacing--l.border-bottom {
        border: none !important;
        .blog-post.o-media {
          height: unset;
          margin: 0 !important;
        }
        .skeleton-box {
          height: 16px !important;
        }
      }
      p {
        margin: 0 !important;
      }
    }
    &.left {
      .popup-arrow-top:after,
      .popup-arrow-bottom:after {
        right: unset;
        left: 10px;
      }
    }
    &.right {
      .popup-arrow-top:after,
      .popup-arrow-bottom:after {
        right: 10px;
        left: unset;
      }
    }
    .close {
      display: none !important;
      position: absolute;
      bottom: 5px;
      right: 30px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: #333;
      &:hover {
        cursor: pointer;
        color: #000;
      }
    }
  }
}
// Markdown Accordian
.markdown-accordian {
  &.open {
    & > * {
      display: block;
    }
    & > :first-child {
      padding-top: 30px !important;
    }
  }
  &.close {
    height: 24px;
    & > * {
      display: none !important;
    }
    & > :first-child {
      padding-right: 30px;
      margin-bottom: 0 !important;
      display: block !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    // Hide if img tag is first parent
    img,
    video,
    iframe,
    .zoomable-image,
    figure {
      display: none !important;
    }
  }
}
