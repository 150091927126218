$errorColor: #c10000;

.tuva-form-control {
  &.tuva-vertical-layout {
    .form-group {
      flex-direction: column;
      .form-item-label {
        margin-bottom: 8px;
      }
      margin-bottom: 14px;
    }
    .form-item-explain-error {
      top: 5px;
      right: 0;
    }
    .form-group:last-child {
      margin-bottom: 20px !important;
    }
  }
  .form-item-explain-error {
    top: 100%;
    bottom: -1px;
  }
  .form-item-has-error {
    .form-item-label,
    .form-item-explain-error {
      color: $errorColor;
    }
  }

  .common-error {
    color: $errorColor;
    padding-top: 15px;
  }
  .form-group {
    margin-bottom: 24px;
  }
  .form-item-input-content {
    input,
    textarea,
    .rc-rate {
      padding-left: 15px;
      border-radius: 4px;
    }

    .twitter-textarea {
      margin-bottom: 10px;
    }

    .form-control,
    .form-select,
    .tuva-select .selection {
      border-color: #989da3;
      min-height: 44px;
    }

    .form-control.focus,
    .form-control:focus,
    .form-select.focus,
    .form-select:focus,
    .tuva-select.focus .selection {
      border-color: #004445;
    }
    .tuva-select .options {
      z-index: 10;
    }
  }
}
