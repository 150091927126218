$gray-100: #f0f5fb !default;
$gray-300: #dadfe5 !default;
$gray-700: #5c6065 !default;
$gray-800: #3d4146 !default;

// -------- greens --------
$green-100: #070808 !default;
$green-800: #005f64 !default;
$green-900: #004445 !default;

$black: #000000 !default;
$white: #fff !default;

.tuva-select {
  width: 100%;

  &:focus {
    outline: 0;
  }

  .label {
    margin-bottom: 6px;
    font-weight: 600;
  }

  .selection {
    border: 1px solid #ced4da;
    background: #fff;
    padding: 5px 30px 5px 5px;
    border-radius: 4px;
  }

  .value {
    padding: 5px 10px;
  }

  .value.text-left.no-wrap {
    padding-bottom: 0;
  }

  .multiple {
    padding-right: 30px;
    margin: 2px;
    background: $gray-100;
    color: $green-800;
  }

  .delete {
    top: 0;
    right: 0;
    padding: 8px;
    font-size: 10px;
    cursor: pointer;
  }

  .placeholder {
    padding: 5px 10px;
    color: $black;
  }

  .arrow {
    top: 15px;
    right: 15px;
    display: block;
    font-size: 10px;
    color: $gray-300;
    &.up-arrow {
      transform: rotate(183deg);
      top: 11px;
    }
  }

  .options {
    top: 100%;
    left: 0;
    right: 0;
    border: solid $gray-100;
    border-width: 0 1px;
    background: #fff;
    margin-top: 10px;
    padding-top: 4px;
    box-shadow: 0px 2px 8px RGB(0 0 0 / 15%);
    max-height: 200px;
    overflow: auto;

    &:focus {
      outline: 0;
      & .selection {
        box-shadow: 0 0 1px 1px $green-800;
      }
    }
  }

  .option {
    padding: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    max-width: 100%;

    &.focused {
      background-color: #e4e7eb;
    }
    &.selected {
      background: $gray-800;
      color: #fff;
    }
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.tuva-checkbox {
  content: '';
  width: 16px;
  height: 16px;
  padding: 2px;
  border: 1px solid $green-900;
  border-radius: 2px;
  margin: 2px 12px 0 0;
  color: #fff;
  font-size: 10px;

  .selected & {
    border-color: $green-900;
    background: $gray-100;
  }
  .fa-check {
    font-size: 12px;
    left: 1px;
  }
}

.btn-loader {
  &.loading {
    .button-loader {
      vertical-align: middle;
      display: inline-block;
      .select-loader--spinner {
        margin-right: 5px;
      }
    }
  }
}

.select-loader--spinner {
  animation: select-loader--spinner 1000ms linear infinite;
}

@keyframes select-loader--spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
