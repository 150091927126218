.placeholder-block {
  min-height: 230px;
  background: linear-gradient(
      to right,
      lightgrey 50%,
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient(lightgrey 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, lightgrey 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(lightgrey 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 6px 1px, 1px 6px;
}
