$gray-500: #989da3 !default;

.markdownEditor {
  // width: 412px;
  border-radius: 3px;
  max-width: 100% !important;
  overflow-x: hidden !important;
  .cm-editor {
    font-family: 'Source Sans Pro', sans-serif, Arial, 'Helvetica Neue',
      Helvetica;
    font-size: 16px;
    height: auto;
    min-height: 200px;
    &.ͼ1 {
      &.cm-focused {
        outline: none;
      }
    }
  }
  button {
    padding: 5px 7px;
    margin: 4px 0;
    border: 1px solid transparent;
    background-color: white;
    &:hover {
      border-radius: 4px;
      border: 1px solid $gray-500;
    }
  }
  /* custom autocomplete menu icon */
  .ͼ1 .cm-completionIcon-tuvatools {
    margin-right: 2px;
    &:after {
      content: '+';
    }
  }
  /* custom autocomplete popup */
  .cm-tooltip-autocomplete {
    max-width: 400px;
    z-index: 1000;
  }
}
